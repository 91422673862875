
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.app {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #fafafa;
}

.top {
    flex: 0;
    border-bottom: solid 1px var(--adm-color-border);
}

.body {
    flex: 1;
    display: flex;
}

.bottom {
    flex: 0;
    border-top: solid 1px var(--adm-color-border);
    /* padding-bottom: 6px; */
}

.row_right {
    width: 100%;
    display: flex;
    justify-content: end;
}

.row_left {
    width: 100%;
    display: flex;
    justify-content: start;
}

.row_center {
    width: 100%;
    display: flex;
    justify-content: center;
}

.col {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.row_space_small {
    width: 100%;
    height: 8px;
}

.row_space_middle {
    width: 100%;
    height: 16px;
}

.row_space_big {
    width: 100%;
    height: 32px;
}

.mask_content {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
}

.mask_content .left, .right {
    flex: 1;
    height: 100%;
}

.mask_content .left, .right {
    opacity: 0.5;
    background-color: #000000;
}

.mask_content .center {
    display: flex;
    flex-direction: column;
}

.mask_content .center .one, .three {
    opacity: 0.5;
    background-color: #000000;
}

.camera_ops {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
}

/* .camera_tab_content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
} */



